import React, { UseEffect, useState } from "react";
import { useEffect } from "react";
import logo from "../assets/images/DSCE.png"
import BG from "../assets/images/study-group.jpg"
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavHeader from "../components/NavHeader";
import Footer from "../components/Footer";
import { asyncLocalStorage } from "../utils/appParams";
import { setCurrentUser } from "../utils/authService";
import api from "../api/api";

const PortalLogin = () => {
  const {state} = useLocation()
  const [username, setUsername] = useState(state ? state?.state?.id : "");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [btnText, setBtnText] = useState("Login")


  const navigate = useNavigate();

  const errorProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const warnProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const successProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const studentLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setBtnText('Loading...')
      const { data } = await api.post("/student/login", {
        username,
        password,
      });
      console.log("Result", data.data);
      setLoading(false);
      // await asyncLocalStorage.setItem("student-token", data.data.tokens.access);
      localStorage.setItem("student-token", data.data.tokens.access);
      setCurrentUser(data.data.tokens.student);
      toast.success("Login successful", successProp);
      setBtnText('Login')
      navigate('/')
      // window.location.replace("/")
    } catch (error) {
      console.log(error);
      setBtnText("Failed, try again")
      setLoading(false);
      toast.error(error.response.data.error.message, errorProp);
    }
  };

const backgroundStyle = {
    backgroundImage: `url(${BG})`,
               height: "100% !important",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  opacity:'01'
}

  return (
    <>
    {/* <NavHeader/> */}
    <div class="account-body">
      <div class="">
        <div class="row vh-100">
          <div class="col-lg-8"  style={backgroundStyle}>
          <div style={{ content: '', backgroundSize: 'cover', position: 'absolute', top: 0, right: 0, bottom: 0, left: 0,backgroundColor: "rgba(0,0,0,0.1)"}}></div>
            <div className="">
                <div class="card-body">
                  <div class="px-3">
                    <div class="auth-logo-text text-center">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              style={{height: "100px", objectFit:'contain', position:'relative'}}
                            />
                      <h2 class="mt-0 mt-3 text-white" style={{position:'relative'}}>Delta State College of Education, Mosogar</h2>
                      <h6 class="text-white mb-2" style={{position:'relative'}}>
                        In Affiliation with
                      </h6>
                      <h2 class="mt-0 mt-1 text-white" style={{position:'relative'}}>Ignatius Ajuru University of Education, Port Harcourt</h2>
                    </div>
                    
            </div>
          </div>
        </div>
          </div>
          <div class="col-lg-4">
            <div class="auth-page mt-5">
              <div class="card auth-card shadow-lg">
                <div class="card-body">
                  <div class="px-3">
                  <div class="text-center auth-logo-text">
                      <h4 class="mt-0 mb-3 mt-5">Student Portal Login</h4>
                      <p class="text-muted mb-0">
                        Sign in to your portal.
                      </p>
                    </div>

                    <form
                      class="form-horizontal auth-form my-4"
                      onSubmit={studentLogin}
                    >
                      <div class="form-group">
                        <label for="username">Phone Number</label>
                        <div class="input-group mb-3">
                          <span class="auth-form-icon">
                            <i class="dripicons-user"></i>
                          </span>
                          <input
                            type="text"
                            class="form-control"
                            id="username"
                            placeholder="Enter phone number"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                          />
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="userpassword">Password</label>
                        <div class="input-group mb-3">
                          <span class="auth-form-icon">
                            <i class="dripicons-lock"></i>
                          </span>
                          <input
                            type="password"
                            class="form-control"
                            id="userpassword"
                            placeholder="Enter password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                        </div>
                      </div>

                      <div class="form-group row mt-4">
                        <div class="col-sm-6">
                          <div class="custom-control custom-switch switch-success">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customSwitchSuccess"
                            />
                            <label
                              class="custom-control-label text-muted"
                              for="customSwitchSuccess"
                            >
                              Remember me
                            </label>
                          </div>
                        </div>
                        <div class="col-sm-6 text-right">
                          <a
                            href="/reset-password"
                            class="text-muted font-13"
                          >
                            <i class="dripicons-lock"></i> Forgot password?
                          </a>
                        </div>
                      </div>

                      <div class="form-group mb-0 row d-flex justify-content-center">
                        {loading ? (
                          <div class="spinner-border text-primary" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                        ) : (
                          <div class="col-12 mt-2">
                            <button
                              class="btn btn-gradient-primary btn-round btn-block waves-effect waves-light"
                              type="submit"
                            >
                              Login <i class="fas fa-sign-in-alt ml-1"></i>
                            </button>
                          </div>
                        )}
                      </div>
                      <ToastContainer />
                    </form>
                  </div>

                  <div class="m-3 text-center text-muted">
                    <p class="">
                      Don't have an account ?{" "}
                      <Link to="/apply/register" class="text-primary ml-2">
                        Register
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    </>



    // <div class="bg-card">
    //     <div class="container-fluid">
    //         <div class="row vh-100">
    //             <div class="col-lg-4 pr-0">
    //                 <div class="auth-page">
    //                     <div class="card mb-0 shadow-none h-100">
    //                         <div class="card-body">
    //                             <Logo/>
    //                             <div class="px-3">
    //                                 <h2 class="font-weight-semibold font-22 mb-2">Delta State Polytechnic, <span class="text-primary">Ogwashi Uku</span>.</h2>
    //                                 <p class="text-muted">Try our fully featured business texting platform.</p>

    //                                 <ul class="nav-border nav nav-pills" role="tablist">
    //                                     <li class="nav-item">
    //                                         <NavLink className="nav-link font-weight-semibold" data-toggle="tab" to="/apply/login" role="tab">Log In</NavLink>
    //                                     </li>
    //                                     <li class="nav-item">
    //                                         <NavLink className="nav-link font-weight-semibold" data-toggle="tab" to="/apply/register" role="tab">Register</NavLink>
    //                                     </li>
    //                                 </ul>
    //                                 <div class="tab-content">
    //                                     <div class="tab-pane active p-3" id="LogIn_Tab" role="tabpanel">
    //                                         <form
    //                   class="form-horizontal auth-form my-4"
    //                   onSubmit={studentLogin}
    //                 >
    //                   <div class="form-group">
    //                     <label for="username">Username</label>
    //                     <div class="input-group mb-3">
    //                       <span class="auth-form-icon">
    //                         <i class="dripicons-user"></i>
    //                       </span>
    //                       <input
    //                         type="text"
    //                         class="form-control"
    //                         id="username"
    //                         placeholder="Enter username"
    //                         value={username}
    //                         onChange={(e) => setUsername(e.target.value)}
    //                         required
    //                       />
    //                     </div>
    //                   </div>

    //                   <div class="form-group">
    //                     <label for="userpassword">Password</label>
    //                     <div class="input-group mb-3">
    //                       <span class="auth-form-icon">
    //                         <i class="dripicons-lock"></i>
    //                       </span>
    //                       <input
    //                         type="password"
    //                         class="form-control"
    //                         id="userpassword"
    //                         placeholder="Enter password"
    //                         value={password}
    //                         onChange={(e) => setPassword(e.target.value)}
    //                         required
    //                       />
    //                     </div>
    //                   </div>

    //                   <div class="form-group row mt-4">
    //                     <div class="col-sm-6">
    //                       <div class="custom-control custom-switch switch-success">
    //                         <input
    //                           type="checkbox"
    //                           class="custom-control-input"
    //                           id="customSwitchSuccess"
    //                         />
    //                         <label
    //                           class="custom-control-label text-muted"
    //                           for="customSwitchSuccess"
    //                         >
    //                           Remember me
    //                         </label>
    //                       </div>
    //                     </div>
    //                     <div class="col-sm-6 text-right">
    //                       <a
    //                         href="auth-recover-pw.html"
    //                         class="text-muted font-13"
    //                       >
    //                         <i class="dripicons-lock"></i> Forgot password?
    //                       </a>
    //                     </div>
    //                   </div>

    //                   <div class="form-group mb-0 row">
    //                     {loading ? (
    //                       <div class="preloader pl-size-xs">
    //                         <div class="spinner-layer pl-red-grey">
    //                           <div class="circle-clipper left">
    //                             <div class="circle"></div>
    //                           </div>
    //                           <div class="circle-clipper right">
    //                             <div class="circle"></div>
    //                           </div>
    //                         </div>
    //                       </div>
    //                     ) : (
    //                       <div class="col-12 mt-2">
    //                         <button
    //                           class="btn btn-gradient-primary btn-round btn-block waves-effect waves-light"
    //                           type="submit"
    //                         >
    //                           Login <i class="fas fa-sign-in-alt ml-1"></i>
    //                         </button>
    //                       </div>
    //                     )}
    //                   </div>
    //                   <ToastContainer />
    //                                         </form>
    //                                         <div class="mx-3 mt-3 text-center bg-light p-3 text-primary">
    //                                             <h4 class="">Don't have an account ? </h4>
    //                                             <Link to="/apply/register" class="btn btn-primary waves-effect waves-light">Register</Link>                
    //                                         </div>
                                            
    //                                     </div>
    //                                 </div> 
    //                             </div>
                                
    //                             <div class="mt-3 text-center">
    //                                 &copy; 2019 - 2020 Metrica <span class="text-muted d-none d-sm-inline-block float-right">Crafted with <i class="mdi mdi-heart text-danger"></i> by Mannatthemes</span>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="col-lg-8 p-0 h-100vh d-flex justify-content-center"
    //               style={{backgroundImage: `url(/assets/images/1bg.jpg)`,
    //               height: "100% !important",
    //               backgroundRepeat: "no-repeat",
    //               backgroundSize: "cover",
    //               opacity:'0.9'}}>
    //                 <div class="accountbg d-flex align-items-center"> 
    //                     <div class="account-title text-center text-white">
    //                         {/* <img src="../assets/images/logo-sm.png" alt="" class="thumb-sm"/> */}
    //                         <h4 class="mt-3 text-white">Welcome To</h4>
    //                         <h1 class="text-white border border-warning bg-warning">Delta State Polytechnic</h1>
    //                         <p class="font-18">Ogwashi Uku</p>
    //                         <div class="border w-25 mx-auto border-warning"></div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
        
    // </div>
  );
};

export default PortalLogin;
