import { useEffect, useRef, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import api from "../../../api/api";
import logo from "../../../assets/images/DSCE.png";
import logo2 from "../../../assets/images/IAUE.png";
import useDetails from "../../../hooks/useDetails";
import { useReactToPrint } from "react-to-print";
const CourseRegPrintout = () => {
  const location = useLocation();
  const [validation, setValidation] = useState();
  const [courses, setCourses] = useState()
  const { data:student } = useDetails();

  const firstSemesterCourses = courses?.filter(course => (course.semester).toLowerCase() === 'first')
  const secondSemesterCourses = courses?.filter(course => (course.semester).toLowerCase() === 'second')

  const totalUnits = courses?.reduce(
    (acc, course) => acc + parseInt(course.unit),
    0
  );

  const componentRef = useRef();

  const pageStyle = `
  @page {
    size: 210mm 297mm;
    margin: 0rem 8rem;
  }
`;
  const printCourseReg = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Course Reg",
    onAfterPrint: () => console.log("Course Reg printed"),
    pageStyle: pageStyle,
  });

  useEffect(() => {
    const validated = async () => {
      try {
        const { data } = await api.get(`/courses/validated/first/100`);
        setValidation(data?.data[0]);
      } catch (error) {}
    };


    validated()
  }, [student])
  

  const getRegisteredCourses = async () => {
    try {
      const { data } = await api.get(`/courses/student-view`);
      setCourses(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRegisteredCourses()
  }, [])
  

  if (courses?.length === 0) {
    return (
      <Navigate
        to={{
          pathname: `/course-reg`,
          state: { from: location },
        }}
        replace
      />
    );
  }

  return (
    <div className="page-wrapper">
      <div className="page-content-tab">
        <div className="container-fluid">
          <div className="card" ref={componentRef}>
          <div className="receipt-header">
              <div className="school-title">
                <img src={logo} alt="DSP" className="logo" />
                <h3 className="name">Delta State College of Education, Mosogar</h3>
              </div>
              <h6 className="text-center">In Affiliation with</h6>
              <div className="school-title">
                <img src={logo2} alt="DSP" className="logo" />
                <h3 className="name">Ignatius Ajuru University of Education,Port harcourt</h3>
              </div>
              <h4 className="subheader">
                Course Registration Slip
              </h4>
            </div>
            <div className="p-5">
              <table>
                <tbody>
                  <tr>
                    <th>
                      <img
                        alt="passport"
                        src={student?.photo}
                        style={{ width: "80px" }}
                      />
                    </th>
                  </tr>
                  <tr>
                    <th>Name</th>
                    <td>{student?.surname + " " + student?.othername}</td>
                  </tr>
                  <tr>
                    <th>Matriculation Number</th>
                    <td>{student?.matric_no}</td>
                  </tr>
                  <tr>
                    <th>Department</th>
                    <td>{student?.department}</td>
                  </tr>
                  <tr>
                    <th>School/Faculty</th>
                    <td>{student?.faculty}</td>
                  </tr>
                  <tr>
                    <th>Programme</th>
                    <td>{student?.programme}</td>
                  </tr>
                  <tr>
                    <th>Session</th>
                    <td>{student?.entry_session}</td>
                  </tr>
                  <tr>
                    <th>Phone Number</th>
                    <td>{student?.phone}</td>
                  </tr>
                </tbody>
              </table>
              <h4 className="d-flex justify-content-center subheader mt-5">
                Courses
              </h4>
              <table>
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Course</th>
                    <th>Unit</th>
                    <th>Semester</th>
                    <th>Signing</th>
                  </tr>
                </thead>
                <tbody>
                  <h4 className="text-center">FIRST SEMESTER</h4>

                  {firstSemesterCourses?.map(({id, code, semester, title, unit}) => (
                    <tr key={id}>
                      <td>{code}</td>
                      <td>{title}</td>
                      <td>{unit}</td>
                      <td>{semester}</td>
                      <td></td>
                    </tr>
                  ))}

                  {secondSemesterCourses?.length > 0 && 
                    <>
                      <h4 className="text-center">SECOND SEMESTER</h4>

                      {secondSemesterCourses?.map(({id, code, semester, title, unit}) => (
                        <tr key={id}>
                          <td>{code}</td>
                          <td>{title}</td>
                          <td>{unit}</td>
                          <td>{semester}</td>
                          <td></td>
                        </tr>
                      ))}

                    </>
                  }
                </tbody>
                <tfoot>
                  <tr>
                    <th>Total units</th>
                    <td></td>
                    <th>{totalUnits}</th>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>Course Advisor</th>
                    <th colSpan={4}></th>
                  </tr>
                  <tr>
                    <th>Director of Programme</th>
                    <th colSpan={4}></th>
                  </tr>
                  <tr>
                    <th>Head of Department (HOD)</th>
                    <th colSpan={4}></th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div className="card">

              <button className="btn dspg-danger" onClick={printCourseReg}>Print file</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseRegPrintout;
