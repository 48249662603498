import { useContext } from "react";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ChevronDown, ChevronUp, Trash2 } from "react-feather";
import { v4 as uuidv4 } from "uuid";
import Footer from "../../components/Footer";
import api from "../../api/api";
import { getCurrentUser } from "../../utils/authService";
import useDetails from "../../hooks/useDetails";
import { GetStudentDetails } from "../../api/student";
import usePayments from "../../hooks/usePayments";
import StudentInfo from "../../components/StudentInfo";

const CourseRegistration = () => {
  const { data:student } = useDetails();
  const {payment} = usePayments()
  const [level, setLevel] = useState();
  const [courses, setCourses] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [registeredCourses, setRegisteredCourses] = useState([])
  const [registeredShow, setRegisteredShow] = useState(false)
  const [validation, setValidation] = useState()
  
  const [grade, setGrade] = useState("");
  const [semester, setSemester] = useState();

  const [toggle, setToggle] = useState(false);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const user = getCurrentUser();

  const token = user?.access;

  const totalUnits = selectedCourses.reduce(
    (acc, course) => acc + parseInt(course.unit),
    0
  );

  const totalRegisteredUnits = registeredCourses.reduce(
    (acc, course) => acc + parseInt(course.unit),
    0
  );

  console.log("totalUnits", totalUnits);

  const getDetails = async () => {
    setLoading(true);
    try {
      const data = await GetStudentDetails(user);
      setLevel(data?.level);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const registerCourses = async (e) => {
    e.preventDefault()
    setLoading(true);
    try {
      const { data } = await api.post("/courses/register", {
        courses: selectedCourses,
      });
      console.log("Registered courses", data);
      window.location.reload()
    } catch (error) {
      console.log(error);
    }
  };

  //   const fetchPaymentStatus = async () => {
  //     try {
  //       const { data } = await GetPaymentStatus(user.application_id);
  //       console.log(data, "statuss");
  //       setAdmissionFee(data.application_fee);
  //       setResultFee(data.result_checking_fee);
  //     } catch (error) {}
  //   };

  const getCourses = async () => {
    try {
      const { data } = await api.get(
        `/courses/all/?level=${level}&semester_int=${semester}`
      );
      console.log("Courses list", data.results);
      setCourses(data.results);
    } catch (error) {
      console.log(error);
    }
  };

  const getRegisteredCourses = async () => {
    try {
      const { data } = await api.get(`/courses/student-view`);
      console.log("Registered courses", data);
      setRegisteredCourses(data);
    } catch (error) {
      console.log(error);
    }
  };

  const MAX_UNITS = 48;

  const handleCourseSelection = (event) => {
    const course = JSON.parse(event.target.value);
    const newTotalUnits = totalUnits + parseInt(course.unit);

    // Check if adding the new course will exceed the maximum units
    if (newTotalUnits > MAX_UNITS) {
      alert(`Cannot exceed maximum of ${MAX_UNITS} units!`);
      return;
    }

    // Check if course is already in selectedCourses array
    if (
      selectedCourses.some((selectedCourse) => selectedCourse.id === course.id)
    ) {
      alert("Course already selected!");
      return;
    }

    setSelectedCourses((prevSelectedCourses) => [
      ...prevSelectedCourses,
      course,
    ]);
  };

  const handleCourseDelete1 = (id) => {
    let newList = selectedCourses.filter((item) => item.id !== id);
    setSelectedCourses(newList);
  };

  const validated = async () => {
    try {
      const {data} = await api.get(`/courses/validated/first/${student?.level}`)
      setValidation(data?.data[0])
      console.log("sasass",data?.data[0])
    } catch (error) {
      
    }
  }

  const deleteRegisteredCourses = async (id) => {
    try {
      const {data} = await api.delete(`/courses/remove-registered-course/${id}`)
      getRegisteredCourses()
      toast.success("Course deleted")
    } catch (error) {
      
    }
  }

  useEffect(() => {
    getDetails();
    getRegisteredCourses()
    // fetchPaymentStatus();
  }, []);

  useEffect(() => {
    getCourses();
    validated()
  }, [level, semester, student]);

  const errorProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const warnProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const successProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  return (
    <div class="page-wrapper">
      {(student && courses && payment) &&
      <div class="page-content-tab">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              <div class="page-title-box">
                <div class="float-right">
                  <ol class="breadcrumb">
                    {/* <li class="breadcrumb-item"><a href="javascript:void(0);">Metrica</a></li>
                            <li class="breadcrumb-item"><a href="javascript:void(0);">Pages</a></li> */}
                    <li class="breadcrumb-item active">Registration</li>
                  </ol>
                </div>
                <h4 class="page-title">Registration</h4>
              </div>
            </div>
          </div>
          {payment?.school_fees_payment?.payment_status === 'success' &&
          <div class="row">
            <div class="col-12">
              <div class="tab-content detail-list" id="pills-tabContent">
                <div class="tab-pane fade show active" id="general_detail">
                  <div class="row">
                    <div class="col-lg-12 col-xl-12 mx-auto">
                      <div class="card">
                        <div class="card-body">
                          <div class="container">
                              <div class="form-group row">
                                <div class="col-md-6">
                                  <label>Current Level</label>
                                  <select
                                    class="form-control mb-3"
                                    disabled
                                  >
                                    <option>{student?.level}</option>
                                  </select>
                                  {/* <button className="btn dspg-success mr-1">
                                    Register
                                  </button> */}
                                </div>
                              </div>
                              <StudentInfo data={student}/>
                              <>
                                <hr />
                                <p className="breadcrumb-item active">
                                  Registration Criteria
                                </p>

                                <div class="form-group row">
                                  <div class="col-md-3">
                                    <label>Semester</label>
                                    <select
                                      class="form-control"
                                      value={semester}
                                      onChange={(e) =>
                                        setSemester(e.target.value)
                                      }
                                    >
                                      <option>--Select Semester--</option>
                                      <option value="1">FIRST</option>
                                      <option value="2">SECOND</option>
                                    </select>
                                  </div>
                                  <div class="col-md-6">
                                    <label>Academic Course</label>
                                    <select
                                      class="form-control"
                                      value={selectedCourses}
                                      onChange={handleCourseSelection}
                                    >
                                      <option>
                                        --Select Academic Course--
                                      </option>
                                      {courses?.map((course) => (
                                        <option
                                          key={course.id}
                                          value={JSON.stringify(course)}
                                        >
                                          {course.code} {course.title}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                <div class="form-group mb-3">
                                  <button
                                    class="btn btn-gradient-primary btn-sm  mb-0"
                                    disabled={loading}
                                    onClick={()=> setRegisteredShow(true)}
                                  >
                                    View Registered Courses
                                  </button>
                                </div>
                                <hr />
                                {selectedCourses.length ? (
                                  <>
                                    <p className="breadcrumb-item">
                                      Selected Courses
                                    </p>
                                    <table className="col-lg-9">
                                      <thead className="dspg-dark">
                                        <th>Subject</th>
                                        <th>Grade</th>
                                        <th>Unit</th>
                                        <th>Semester</th>
                                        <th></th>
                                      </thead>
                                      {selectedCourses?.map((list) => (
                                        <tbody>
                                          <td>{list.code}</td>
                                          <td>{list.title}</td>
                                          <td>{list.unit}</td>
                                          <td>{list.semester}</td>
                                          <td
                                            onClick={() =>
                                              handleCourseDelete1(list.id)
                                            }
                                          >
                                            <Trash2 className="waves-effect align-self-center icon-dual-pink icon-sm" />
                                          </td>
                                        </tbody>
                                      ))}
                                      <tfoot>
                                        <td colSpan={5}>
                                          <button type="submit" className="dspg-success" onClick={registerCourses}>
                                            Register
                                          </button>
                                        </td>
                                      </tfoot>
                                    </table>
                                  </>
                                ) : (
                                  ""
                                )}
                                <hr />
                                {registeredShow ?
                                <>
                                {registeredCourses.length ? (
                                  <>
                                    <p className="breadcrumb-item">
                                      Registered Courses
                                    </p>
                                    {/* <div className="alert dspg-info"><b>Note!</b> You cannot delete courses after the Course Advisor have approved your course registration </div> */}
                                    <table className="col-lg-9">
                                      <thead className="dspg-dark">
                                        <th>Subject</th>
                                        <th>Grade</th>
                                        <th>Unit</th>
                                        <th>Semester</th>
                                        <th></th>
                                      </thead>
                                      {registeredCourses?.map((list) => (
                                        <tbody>
                                          <td>{list.code}</td>
                                          <td>{list.title}</td>
                                          <td>{list.unit}</td>
                                          <td>{list.semester}</td>
                                          {validation?.is_validated ?
                                          <td></td> :
                                          <td
                                            onClick={() =>
                                              deleteRegisteredCourses(list.id)
                                            }
                                          >
                                            <Trash2 className="waves-effect align-self-center icon-sm" />
                                          </td>}
                                        </tbody>
                                      ))}
                                      <tfoot>
                                        <tr>
                                        <td className="h5">
                                          Total Units
                                        </td>
                                        <td></td>
                                        <td className="h5">
                                          {totalRegisteredUnits}
                                        </td>
                                        <td></td>
                                        <td></td>
                                        </tr>
                                        <tr>
                                        <td colSpan={6} className="h5">
                                          Course Advisor: 
                                        </td>
                                        </tr>
                                        <tr>
                                        <td colSpan={6}>
                                          {registeredCourses?.length &&
                                          <button type="submit" className="dspg-danger" onClick={() => navigate("/course-reg/print")}>
                                            Go to Printout
                                          </button>
                                          }
                                        </td>
                                        </tr>
                                      </tfoot>
                                    </table>
                                  </>
                                ) : (
                                  <div className="alert dspg-info">No Courses Registered Yet</div>
                                )}
                                </>
                              :''}
                              </>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          }
          {payment?.school_fees_payment?.payment_status !== 'success' &&
          <div class="alert dspg-warning" role="alert">
          Please Pay Your School Fees First
        </div>
        }
        </div>

        <Footer />
      </div>
      }
    </div>
  );
};

export default CourseRegistration;
