import { User, CreditCard } from "react-feather"
import { useNavigate } from "react-router"
import usePayments from "../hooks/usePayments"
import { VerifyPayments } from "../api/student"
import { useState } from "react"
import { toast } from "react-toastify"
const SmallCard = ({title, paymentStatus, invoice, type}) => {
    
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    console.log(paymentStatus, 'Omohhh')

    const paymentRequery = async (ref) => {
        if (!ref) return alert('No Payment Reference Found')
        try {
            setLoading(true)
            toast.loading("Requering in Progress")
            const response = await VerifyPayments(ref);
            setLoading(false)
            window.location.reload()
        } catch (error) {
          toast.error('Verification Failed')
          setLoading(false)
        }
    }

    
  return (
    <>
    <div class="card report-card">
        <div className={`card-body ${paymentStatus === null?
                "dspg-danger"
                    : (paymentStatus !== null && paymentStatus?.payment_status === "success") ? "dspg-success"
                        : (paymentStatus !== null && paymentStatus?.payment_status === "pending") ? 'dspg-warning'
                            : ''
                            }`}>
            <div class="row d-flex justify-content-center">
                <div class="col-8">
                    <p class="text-dark font-weight-semibold font-14">{title}</p>
                    <div className="d-flex align-items-center">
                        <h3 class="my-3">{paymentStatus === null ? "Not Paid"
                            : paymentStatus !== null && paymentStatus?.payment_status === "success" ? "Paid"
                                :paymentStatus !== null && paymentStatus?.payment_status === "pending" ? "Pending"
                                    : "Not-Paid"}</h3>
                        <span className="ml-2 text-black"><b>({(type.toUpperCase())})</b></span>

                    </div>
                    {(paymentStatus?.payment_status === "success" && paymentStatus?.paystack_ref) ? 
                    <button class="btn btn-light" onClick={() => navigate('/invoice/school-fee', {state: { invoice } })}>Print Receipt</button>
                            : (paymentStatus?.payment_status === "pending" && paymentStatus?.paystack_ref) ?
                            <button class="btn btn-warning" disabled={loading} onClick={() => paymentRequery(paymentStatus?.paystack_ref)}>Requery</button>
                            : null
                    }
                </div>
                <div class="col-4 align-self-center">
                    <div class="report-main-icon bg-light-alt">
                        <CreditCard class="align-self-center icon-dual-pink icon-lg"></CreditCard>  
                    </div>
                </div>
            </div>
        </div> 
    </div>
    
    </>
  )
}

export default SmallCard