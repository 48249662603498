import useDetails from "../hooks/useDetails";
import { numberWithCommas } from "../utils/appParams";
import StudentInfo from "./StudentInfo";
import "./Style.css";

const SchoolFeeComponent = ({
  loading,
  btnText,
  handlePayment,
  schoolFee,
  setPaymentType,
  paymentType
}) => {
  const { data } = useDetails();
  //   const {data:datas} = useProspective()
  return (
    <>
      {data && (
        <div>
          <div class="card p-5" style={{ padding: "10px" }}>
            <StudentInfo data={data} />
            <hr style={{ border: "2px solid #E8E8E8" }} />
            <div className="col-md-6">
              <label className="text-danger">
                Please select payment category (Full or Part payment)
              </label>
              <select
                className="form-control"
                value={paymentType}
                onChange={(e) => setPaymentType(e.target.value)}
              >
                <option value=''>--Select--</option>
                <option value="full">Full Payment</option>
                <option value="part">Part Payment</option>
              </select>
            </div>
            {paymentType && 
             <div class="pricing p-3 rounded mt-4 mb-4 d-flex justify-content-between">
              <div class="d-flex flex-row align-items-center">
                <sup class="dollar font-weight-bold">₦</sup>
                <span class="amount ml-1 mr-1">
                  {schoolFee && numberWithCommas(schoolFee?.amount)}
                </span>
              </div>
            </div> 
            }
            <div class="mt-3">
              <button
                class="btn btn-primary btn-raised waves-effect"
                onClick={handlePayment}
                disabled={loading || !paymentType}
              >
                {btnText} <i class="fa fa-long-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SchoolFeeComponent;
